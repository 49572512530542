<template>
  <div>
    <b-container fluid>
      <div class="pagdiv">
        <span class="pageTitle">KPI 每月報告</span>
      </div>

      <div class="borderColor contentWrap">
        <!-- search download -->
        <div class="d-flex align-items-center flex-wrap" style="margin: 10px">
          <div class="ml-2 mr-4 my-2">
            日期:
            <date-picker
              class="ml-2"
              style="width: 150px"
              value-type="format"
              format="YYYY-MM"
              type="month"
              v-model="selectedYearMonth"
              placeholder="請選擇日期"
              :disabled-date="disabledDate"
            />
          </div>

          <button
            type="button"
            class="confirmButton"
            style="width: 120px; padding: 2px"
            @click="handleSearchKpi"
            :class="{ disableButton: isLoadingData || !selectedYearMonth }"
          >
            查詢
          </button>

          <button
            type="button"
            class="confirmButton ml-auto mr-2"
            style="width: 120px; padding: 2px"
            @click="downloadExcel"
            :class="{
              disableButton: isDownloadingData || !sortedTableData?.length,
            }"
          >
            下載
          </button>
        </div>

        <!-- table -->
        <b-overlay
          :show="isLoadingData"
          spinner-variant="secondary"
          variant="white"
          class="mx-2"
          z-index="60"
        >
          <div
            class="d-flex align-items-center flex-wrap relative"
            style="margin: 10px"
          >
            <!-- summary table -->
            <b-table-simple
              bordered
              small
              responsive
              class="tableStyle summaryTable"
            >
              <b-thead>
                <b-tr>
                  <b-th colspan="2">符合該年月份計算的案場數</b-th>
                  <b-th colspan="3">目標值 案場數合格狀況</b-th>
                  <b-th colspan="3">保證值 案場數合格狀況</b-th>
                </b-tr>
                <b-tr>
                  <b-th class="align-middle"
                    >{{ summaryData.searchedYear }} 年</b-th
                  >
                  <b-th class="align-middle"
                    >{{ summaryData.searchedMonth }} 月</b-th
                  >
                  <b-th class="c-blue"
                    ><span>符合計算的<br />案場數</span></b-th
                  >
                  <b-th class="c-orange"
                    ><span>目標值達標的<br />案場數</span></b-th
                  >
                  <b-th>
                    <span class="c-orange">達標案場數</span> /<br />
                    <span class="c-blue">符合計算案場數</span> (%)
                  </b-th>
                  <b-th class="c-blue"
                    ><span>符合計算的<br />案場數</span></b-th
                  >
                  <b-th class="c-green"
                    ><span>保證值達標的<br />案場數</span></b-th
                  >
                  <b-th>
                    <span class="c-green">達標案場數</span> /<br />
                    <span class="c-blue">符合計算案場數</span> (%)
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-th sticky-column rowspan="3" class="align-middle"
                    >合格率統計項目</b-th
                  >
                  <b-th sticky-column>PR 值 狀況統計</b-th>
                  <b-td>{{ summaryData.PRAchievedCount }}</b-td>
                  <b-td>{{ summaryData.PRAchievedQualifiedCount }}</b-td>
                  <b-td>{{ summaryData.PRAchievementCountRatio }}</b-td>
                  <b-td>{{ summaryData.PRGuaranteeCount }}</b-td>
                  <b-td>{{ summaryData.PRGuaranteeQualifiedCount }}</b-td>
                  <b-td>{{ summaryData.PRGuaranteeCountRatio }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th sticky-column>發電量 狀況統計</b-th>
                  <b-td>{{ summaryData.powerAchievedCount }}</b-td>
                  <b-td>{{ summaryData.powerAchievedQualifiedCount }}</b-td>
                  <b-td>{{ summaryData.powerAchievementCountRatio }}</b-td>
                  <b-td>{{ summaryData.powerGuaranteeCount }}</b-td>
                  <b-td>{{ summaryData.powerGuaranteeQualifiedCount }}</b-td>
                  <b-td>{{ summaryData.powerGuaranteeCountRatio }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <!-- data table -->
            <b-table
              hover
              small
              :items="sortedTableData"
              :fields="tableFields"
              :responsive="true"
              class="tableStyle dataTable"
              :current-page="currentPage"
              :per-page="perPage"
              bordered
              sort-icon-left
              no-sort-reset
            >
              <!-- <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>&emsp;查詢中...</strong>
              </div>
            </template> -->

              <template #thead-top="_data">
                <b-tr>
                  <b-th colspan="8">
                    <div class="d-flex align-items-center">
                      <span class="mx-1">每頁顯示</span>
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        style="width: 70px"
                        class="mx-2"
                      />
                      <span class="mx-2">
                        筆 ， 共 <span class="mx-3">{{ tableDataNum }}</span> 筆
                      </span>
                    </div>
                  </b-th>
                  <b-th colspan="2" class="align-middle"
                    >PR 值 達標狀況 (%)</b-th
                  >
                  <b-th colspan="2" class="align-middle"
                    >發電量 達標狀況 (%)</b-th
                  >
                </b-tr>
              </template>

              <template #head()="data">
                <div v-if="data.label === '實際值 / 目標值'">
                  <span class="c-blue">實際值</span> /
                  <span class="c-orange">目標值</span>
                </div>

                <div v-else-if="data.label === '實際值 / 保證值'">
                  <span class="c-blue">實際值</span> /
                  <span class="c-green">保證值</span>
                </div>

                <div v-else-if="data.label.includes('目標')" class="c-orange">
                  {{ data.label }}
                </div>
                <div v-else-if="data.label.includes('實際')" class="c-blue">
                  {{ data.label }}
                </div>
                <div v-else-if="data.label.includes('保證')" class="c-green">
                  {{ data.label }}
                </div>
              </template>

              <template #cell()="data">
                <span
                  v-if="
                    [
                      'prAchieveGoal',
                      'prAchieveGuarantee',
                      'powerAchieveGoal',
                      'powerAchieveGuarantee',
                    ].includes(data.field.key)
                  "
                  :class="{
                    'text-danger': isNumber(data.value) && data.value < 100,
                    'c-green': isNumber(data.value) && data.value >= 100,
                  }"
                >
                  {{ getNumberOrDash(data.value) }}
                </span>

                <span
                  v-else-if="
                    ['factoryName', 'capacity'].includes(data.field.key)
                  "
                >
                  {{ data.value }}
                </span>

                <span v-else>{{ getNumberOrDash(data.value) }}</span>
              </template>
            </b-table>

            <b-pagination
              v-model="currentPage"
              v-show="tableDataNum > perPage"
              :per-page="perPage"
              :total-rows="tableDataNum"
              class="ml-2 mb-1"
            />
          </div>
        </b-overlay>
      </div>
    </b-container>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import {
  BTable,
  BTr,
  BTd,
  BTh,
  BPagination,
  BFormSelect,
  BTableSimple,
  BThead,
  BTbody,
  BOverlay
} from 'bootstrap-vue'
import { kpiDataDownload } from '../store/function/common/kpiDataDownload'
import { getKpiApi } from '../api'
export default {
  components: {
    BTable,
    BTr,
    BTh,
    BTd,
    BPagination,
    BFormSelect,
    BTableSimple,
    BThead,
    BTbody,
    BOverlay
  },
  data () {
    return {
      selectedYearMonth: DateTime.local()
        .setZone('Asia/Taipei')
        .toFormat('yyyy-MM'),
      searchedYearMonth: '',
      isLoadingData: false,
      isDownloadingData: false,
      tableFields: [
        { key: 'factoryName', label: '案場名稱', stickyColumn: true },
        { key: 'capacity', label: '案場容量', sortable: true },
        { key: 'prAll', label: '實際 PR 值 (%)', sortable: true },
        { key: 'PRGoalContract', label: '目標 PR 值 (%)', sortable: true },
        { key: 'guaranteePR', label: '保證 PR 值 (%)', sortable: true },
        { key: 'acpAll', label: '實際發電量 (kWh)', sortable: true },
        { key: 'acpGoalContract', label: '目標發電量 (kWh)', sortable: true },
        { key: 'guaranteePower', label: '保證發電量 (kWh)', sortable: true },
        { key: 'prAchieveGoal', label: '實際值 / 目標值', sortable: true }, // t1
        { key: 'prAchieveGuarantee', label: '實際值 / 保證值', sortable: true }, // t2
        { key: 'powerAchieveGoal', label: '實際值 / 目標值', sortable: true }, // t3
        {
          key: 'powerAchieveGuarantee',
          label: '實際值 / 保證值',
          sortable: true
        } // t4
      ],
      tableApiData: [],
      summaryApiData: {},
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100]
    }
  },
  computed: {
    summaryData () {
      return {
        searchedYear: this.searchedYearMonth.split('-')[0] || '--',
        searchedMonth: this.searchedYearMonth.split('-')[1] || '--',
        // PR
        PRAchievedCount: this.getNumberOrDash(
          this.summaryApiData.PRAchievedCount
        ), // c4
        PRAchievedQualifiedCount: this.getNumberOrDash(
          this.summaryApiData.PRAchievedQualifiedCount
        ), // d4
        PRAchievementCountRatio: this.getNumberOrDash(
          this.summaryApiData.PRAchievementCountRatio
        ), // e4
        PRGuaranteeCount: this.getNumberOrDash(
          this.summaryApiData.PRGuaranteeCount
        ), // f4
        PRGuaranteeQualifiedCount: this.getNumberOrDash(
          this.summaryApiData.PRGuaranteeQualifiedCount
        ), // g4
        PRGuaranteeCountRatio: this.getNumberOrDash(
          this.summaryApiData.PRGuaranteeCountRatio
        ), // h4
        // 發電量
        powerAchievedCount: this.getNumberOrDash(
          this.summaryApiData.powerAchievedCount
        ), // c5
        powerAchievedQualifiedCount: this.getNumberOrDash(
          this.summaryApiData.powerAchievedQualifiedCount
        ), // d5
        powerAchievementCountRatio: this.getNumberOrDash(
          this.summaryApiData.powerAchievementCountRatio
        ), // e5
        powerGuaranteeCount: this.getNumberOrDash(
          this.summaryApiData.powerGuaranteeCount
        ), // f5
        powerGuaranteeQualifiedCount: this.getNumberOrDash(
          this.summaryApiData.powerGuaranteeQualifiedCount
        ), // g5
        powerGuaranteeCountRatio: this.getNumberOrDash(
          this.summaryApiData.powerGuaranteeCountRatio
        ) // h5
      }
    },
    sortedTableData () {
      // t2 null, t2 number, t4 null, t4 number ...
      const data = this.tableApiData.slice().sort((a, b) => {
        // t2 排序
        if (a.prAchieveGuarantee !== b.prAchieveGuarantee) {
          // 将 null 值排在前面
          if (a.prAchieveGuarantee === null) return -1
          if (b.prAchieveGuarantee === null) return 1

          // 其他情况按照数值大小进行排序
          return a.prAchieveGuarantee - b.prAchieveGuarantee
        } else if (a.powerAchieveGuarantee !== b.powerAchieveGuarantee) {
          // 如果 t2 相等，t4 排序
          if (a.powerAchieveGuarantee === null) return -1
          if (b.powerAchieveGuarantee === null) return 1

          return a.powerAchieveGuarantee - b.powerAchieveGuarantee
        } else if (a.prAchieveGoal !== b.prAchieveGoal) {
          // 如果 t2 和 t4 都相等，t1 排序
          if (a.prAchieveGoal === null) return -1
          if (b.prAchieveGoal === null) return 1

          return a.prAchieveGoal - b.prAchieveGoal
        } else {
          // 如果 t2、t4 和 t1 都相等，t3 排序
          if (a.powerAchieveGoal === null) return -1
          if (b.powerAchieveGoal === null) return 1

          return a.powerAchieveGoal - b.powerAchieveGoal
        }
      })

      return data
    },
    tableDataNum () {
      return this.tableApiData.length
    }
  },
  methods: {
    isNumber (value) {
      return typeof value === 'number' && !isNaN(value)
    },
    getNumberOrDash (num) {
      if (!this.isNumber(num)) return '--'
      return Math.round(num * 100) / 100
    },
    disabledDate (date) {
      const today = new Date()
      today.setDate(today.getDate())
      today.setHours(0, 0, 0, 0)
      return date > new Date(today.getTime())
    },

    async handleSearchKpi () {
      if (!this.selectedYearMonth) return this.$swal('請輸入查詢日期')
      this.isLoadingData = true

      try {
        const res = await getKpiApi(this.selectedYearMonth)

        if (!res.data) return this.$swal('無資料，請重新查詢')

        if (res?.data?.data?.dataByFactory) {
          this.tableApiData = res.data.data.dataByFactory
        }
        if (res?.data?.data?.dataSummary) {
          this.summaryApiData = res.data.data.dataSummary
        }
      } catch (error) {
        console.log('getKpiApi error', error)
      } finally {
        this.searchedYearMonth = this.selectedYearMonth
        this.isLoadingData = false
      }
    },

    async downloadExcel () {
      if (!this.tableDataNum) return this.$swal('無下載資料，請先查詢資料')

      this.isDownloadingData = true
      try {
        await kpiDataDownload(
          this.searchedYearMonth,
          this.tableFields,
          this.sortedTableData,
          this.summaryApiData
        )
      } catch (error) {
        console.log('kpiDataDownload error', error)
      } finally {
        this.isDownloadingData = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-blue {
  color: #1fa3c8;
}
.c-orange {
  color: #e67a00;
}
.c-green {
  color: #0fb468;
}

// cover css ---------
:deep(.table) {
  thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }

  th.table-b-table-default.b-table-sticky-column {
    background-color: rgba(246, 247, 245);
  }
}
// ------------------

.contentWrap {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.summaryTable {
  max-width: 1000px;
}

.dataTable {
  max-height: calc(100dvh - 440px);
}
</style>
